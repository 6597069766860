// window.sbapi = "https://easyapi.superduper.pk";
// window.imagesapi = "http://admin.superduper.pk";
// export const DepartmentApi = () => dispatch => {

//     fetch(`${window.sbapi}/api/Department/DGSData?BusinessId=0000000001`)
//         .then(res => res.json())
//         .then(json => {
//     dispatch({
//         type: 'get_department_data',
//         data: json.Data
//     })})
// }


// export const PoductsApi = (dep, grup) => dispatch => {
    
//     fetch(`${window.sbapi}/api/Product/ProductEcomData?DepartmentCode=${dep}&GroupCode=${grup}&SubGroupCode=&FromIndex=1&ToIndex=12&BusinessId=0000000001`)
//         .then(res => res.json())
//         .then(json => {
//             const data = json.Data.map((item) => {
//                 item.qty = 1
//                 return { ...item }
//             })
//             dispatch({
//                 type: 'get_allproducts_data',
//                 data: data
//             })
//         }
//         )
// }


// export const fetchApi = () => dispatch => {
//     fetch(`${window.sbapi}/api/Product/PromoandNewArrivalProducts?BusinessId=0000000001`)
//         .then(res => res.json())
//         .then(json => {
//             const data = json.Data;
            
//             const newArrival=data.NewArrivalProducts.map((item) => {
//                 item.qty = 1
//                 return { ...item }
//             })
//             const promo=data.PromoProducts.map((item) => {
//                 item.qty = 1
//                 return { ...item }
//             })
//             dispatch({
//                 type: 'get_products_data',
//                 data: {promotional:promo,newArrival:newArrival}
//             })
//         }
//         )
// }

// export const AddToCart = (data) => dispatch => {
//     dispatch({
//         type: 'add_to_cart',
//         payload: data
//     })
// }

// export const SetQty = (data) => dispatch => {
//     dispatch({
//         type: 'set_qty',
//         payload: data
//     })
// }

// export const RemoveItem = (data) => dispatch => {
//     dispatch({
//         type: 'remove_item',
//         payload: data
//     })
// }

// export const ClearCart = () => dispatch => {
//     dispatch({
//         type: 'clear_cart',

//     })
// }

// export const ChangeName = (data) => dispatch => {
//     dispatch({
//         type: 'change_name',
//         payload:data
//     })
// }


// export const BrandPoductsApi = (code) => dispatch => {
//     // console.log("@@@@@@@@@@@@@@@@@@@" + code)
//     let url = code === "all-data" ? `${window.sbapi}/api/product/ProductsByBrand?BrandCode=&FromIndex=1&ToIndex=20&BusinessId=0000000001` : `${window.sbapi}/api/product/ProductsByBrand?BrandCode=${code}&FromIndex=1&ToIndex=20&BusinessId=0000000001`


//     fetch(url)
//         .then(res => res.json())
//         .then(json => {
//             const data = json.Data.map((item) => {
//                 item.qty = 1
//                 return { ...item }
//             })
//             dispatch({
//                 type: 'get_brandproducts_data',
//                 data: data
//             })
//         }
//         )
// }

// export const BrandApi = (a) => dispatch => {
//     fetch(`${window.sbapi}/api/product/getbrands?BusinessId=0000000001`)

//         .then(res => res.json())
//         .then(json => {
//             dispatch({
//                 type: 'get_Brand_data',
//                 data: a,

//             })
//         })
// }

// export const LoginData = (data) => dispatch => {
//     dispatch({
//         type: 'login_data_checkout',
//         payload:data
//     })
// }

import { Data } from "@react-google-maps/api";
import axios from "axios";





window.sbapi = "https://easyapi.superduper.pk";
window.imagesapi = "http://admin.superduper.pk";


export const DepartmentApi = () => dispatch => {
    fetch(`${window.sbapi}/api/Department/DGSData?BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
            dispatch({
                type: 'get_department_data',
                data: json.Data
            })
        })
}


export const BrandApi = () => dispatch => {
    fetch(`${window.sbapi}/api/product/getbrands?BusinessId=0000000001`)

        .then(res => res.json())
        .then(json => {
            dispatch({
                type: 'get_Brand_data',
                data: json.Data,

            })
        })
}

let Limit = 50;
export const PoductsApi = (dep, grup, sbGroup) => dispatch => {
    console.log(dep)
    // ${sbGroup?sbGroup:null}
    fetch(`${window.sbapi}/api/Product/ProductEcomData?DepartmentCode=${dep ? dep : '0001'}&GroupCode=${grup ? grup : "0001"}&SubGroupCode=${sbGroup ? sbGroup : "0001"}&FromIndex=1&ToIndex=12&BusinessId=0000000001`)
        .then(res => res.json())

        .then(json => {
            const data = json.Data.map((item) => {
                item.qty = 1
                return { ...item }
            })
            localStorage.setItem("MYData", JSON.stringify(data))



            dispatch({
                type: 'get_allproducts_data',
                data: data
            })
        }
        )
}


export const fetchApi = () => dispatch => {
    fetch(`${window.sbapi}/api/Product/PromoandNewArrivalProducts?BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
            const data = json.Data;

            const newArrival = data.NewArrivalProducts.map((item) => {
                item.qty = 1
                return { ...item }
            })
            const promo = data.PromoProducts.map((item) => {
                item.qty = 1
                return { ...item }
            })
            dispatch({
                type: 'get_products_data',
                data: { promotional: promo, newArrival: newArrival }
            })
        }
        )
}

export const AddToCart = (data) => dispatch => {
    dispatch({
        type: 'add_to_cart',
        payload: data
    })
}

export const SetQty = (data) => dispatch => {
    dispatch({
        type: 'set_qty',
        payload: data
    })
}

export const RemoveItem = (data) => dispatch => {
    dispatch({
        type: 'remove_item',
        payload: data
    })
}

export const ClearCart = () => dispatch => {
    dispatch({
        type: 'clear_cart',

    })
}




export const VegpoductsApi = () => dispatch => {
    fetch(`${window.sbapi}/api/Product/ProductEcomData?DepartmentCode=0004&GroupCode=&SubGroupCode=&FromIndex=1&ToIndex=5&BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
            const data = json.Data.map((item) => {
                item.qty = 1
                return { ...item }
            })
            dispatch({
                type: 'get_vegproducts_data',
                data: data
            })
        }
        )
}

export const BrandPoductsApi = (code) => dispatch => {
    console.log("@@@@@@@@@@@@@@@@@@@" + code)
    let url = code === "all-data" ? `${window.sbapi}/api/product/ProductsByBrand?BrandCode=&FromIndex=1&ToIndex=20&BusinessId=0000000001` : `${window.sbapi}/api/product/ProductsByBrand?BrandCode=${code}&FromIndex=1&ToIndex=20&BusinessId=0000000001`


    fetch(url)
        .then(res => res.json())
        .then(json => {
            const data = json.Data.map((item) => {
                item.qty = 1
                return { ...item }
            })
            dispatch({
                type: 'get_brandproducts_data',
                data: data
            })
        }
        )
}





export const FiltrApi = (data) => dispatch => {
    dispatch({
        type: 'FilterData',
        payload: data
    })




}
export const setBrandInfo = (data) => dispatch => {
    dispatch({
        type: 'BRAND_INFO',
        payload: data
    })




}






export const variationData = (data) => dispatch => {
    dispatch({
        type: 'variation-Data',
        payload: data
    })




}

export const setItemData = (data) => dispatch => {
    if ('qty' in data != true) { data.qty = 1 }
    dispatch({
        type: 'set-item-date',
        payload: data
    })




}

export const ChangeName = (data) => dispatch => {
    dispatch({
        type: 'change_name',
        payload: data
    })
}

export const MoreData = (data) => dispatch => {
    dispatch({
        type: 'more data',
        payload: data
    })
}
const showData = () => {
    setTimeout(() => {

        Limit = Limit + 5;
        PoductsApi();




    }, 300)


}
window.addEventListener('scroll', () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight) {
        console.log(Limit)
        showData();

    }
})






