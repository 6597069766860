import React, { useState } from 'react'
import './Footer.css';
import { BiMap } from 'react-icons/bi';
import { IoIosCall } from 'react-icons/io';
import { MdOutgoingMail } from 'react-icons/md';
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Link } from "react-router-dom";
import TrackMyOrder from '../../Screens/TrackMyOrder';
import Orderhistory, { Corderhis } from '../../Screens/Orderhistory';

const Footer = () => {

    const [openModal, setOpenModal] = useState(false)


    return (
        <>
            <div className='signup'>
                <div>
                    <h2> Sign Up For The Newsletter</h2>
                </div>
                <div>
                    <input className='signup-input' type="email" placeholder='Enter Your Email' />

                </div>

                <div>
                    <input className='signup-input' type="number" placeholder='Enter Your Mobile Number' />
                </div>

                <div>
                    <button onClick={"#"}>
                        <p> Subscribe </p>
                    </button>
                </div>

            </div>
            <div style={{width:"100%", backgroundColor:"#c7e9f2"}}>
                <div className='main-footer'>

                    <div>
                        <h3>About Us</h3>
                        <p>
                            superduper.pk is perhaps the largest supermarket, serving Chichawatni & Mian Channu.<Link to={'/aboutus'} style={{ color: 'red' }} onClick={() => { window.scrollTo(0, 0) }}> Read More </Link>
                        </p>

                        <ul className='fc1-link'>
                            <li>
                                <a href='https://maps.app.goo.gl/rsPYxRQFHzp3Y5Mk9' target='_blank'> <BiMap className='map' />
                                  Chichawatni
                                </a>
                            </li>
                            <li>
                                <a href='https://maps.app.goo.gl/vf77sqZTALGu71XS8' target='_blank'> <BiMap className='map' />
                                   Mian Channu
                                </a>
                            </li>
                            <li>
                                <a href='tel:+923330333183'>  <IoIosCall className='map' />
                                    +92 333 0333 183
                                </a>
                            </li>
                            <li >
                                <a> <Link to={'/contactus'} onClick={() => { window.scrollTo(0, 0) }}><MdOutgoingMail className='map' /> sales@superduper.pk
                                </Link></a>
                            </li>
                        </ul>

                    </div>
                    {/* <div>
                        <h3> QUICK LINKS</h3>
                        <ul >
                            <li>
                                <a href='#'>
                                    Special Offer
                                </a>
                            </li>
                            <li>
                                <a href='#'>
                                    Ramzan Offer
                                </a>
                            </li>
                            <li>
                                <Link to={'/getlocation'} onClick={() => { window.scrollTo(0, 0) }}>Get Location</Link>
                            </li>
                        </ul>
                    </div> */}
                    <div>
                        <h3> INFORMATION </h3>
                        <ul >
                            <li>
                                <Link to={'/aboutus'} onClick={() => { window.scrollTo(0, 0) }}>About Us</Link>
                            </li>
                            <li>
                                <Link to={'/contactus'} onClick={() => { window.scrollTo(0, 0) }}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={'/privacypolicy'} onClick={() => { window.scrollTo(0, 0) }}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={'/termcondition'} onClick={() => { window.scrollTo(0, 0) }}>Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to={'/deliveryinfo'} onClick={() => { window.scrollTo(0, 0) }}>Delivery Information</Link>
                            </li>
                        </ul>

                    </div>
                    <div>
                        <h3> SERVICE </h3>
                        <ul >
                            <li>
                                <Link to={'/viewcart'} onClick={() => { window.scrollTo(0, 0) }}   > View Cart</Link>
                            </li>
                            <li>
                                <p className='track-myorder' onClick={() => setOpenModal(!openModal)}>
                                    Track My Order
                                </p>
                                <span style={{ position: 'relative' }} >

                                    <span className='open-ordertrack' style={{ display: openModal ? 'flex' : 'none', }}  >
                                        <TrackMyOrder closeModal={setOpenModal} />
                                    </span>
                                </span>
                            </li>
                            <li>
                                <Link to={'/checkout'} onClick={() => { window.scrollTo(0, 0) }}> Checkout</Link>
                            </li>
                            <li>
                                <Link to={'/orderhistory'}  > Order History </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3> SOCIAL MEDIA </h3>
                        <ul >
                            <span className='social' >
                                <a href='https://www.facebook.com/superdupersupermart?mibextid=ZbWKwL' target="_blank">
                                    <FaFacebook className='facebook' />
                                </a>
                                <a href='https://instagram.com/sbstore_fsd?igshid=YmMyMTA2M2Y=' target="_blank">
                                    <FaInstagram className='instagram' />
                                </a>
                                <a href='https://youtube.com/@superdupercw?si=igeJA_kB32ShImt0' target="_blank">
                                    <FaWhatsapp className='youtube' />
                                </a>
                            </span>
                        </ul>
                        <span className='social' >
                            <a href='https://apps.apple.com/pk/app/' target="_blank">
                                <img src={require("./../../assets/appstore_v1.png")} />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.supperdupperstore' target="_blank">
                                <img src={require("./../../assets/googleplay_v1.png")} />
                            </a>
                        </span>
                    </div>

                </div>
            </div>
            <div className='copy-rights'>
                <p>
                    © 2024 All Rights Reserved - Powered by
                    <a href='https://www.softvalley.com.pk/' target='_blank'> Softvalley</a>
                </p>
            </div>
            {/* { toggleOrder ? 
             <div className='toggletrack-order' >

            </div>
            : null
} */}
        </>
    )
}

export default Footer;