import React, { useEffect, useState } from 'react';
import './Body.css';
import Apiproduct from './Apiproduct';
import { Link } from 'react-router-dom';
import SimpleImageSlider from "react-simple-image-slider";
import { useSelector, useDispatch } from 'react-redux';
import { PoductsApi } from '../Redux/Action';
import { BsChevronDoubleRight } from 'react-icons/bs';
import VegApiproduct from './VegApiproduct';
import FrozenApiproduct from './FrozenApiproduct';
import GroApiproduct from './GroApiproduct';
import DrinksApiproduct from './DrinksApiproduct';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { ChangeName } from "../Redux/Action"


// Main Body

const Body = () => {
    const fetchAp = useSelector(state => state.Reducerprodata)
    const [proData, setProData] = useState(null);
    const [proData1, setProData1] = useState(null);



    const dispatch = useDispatch()

    const sliderImages = [
        URL = require("./../../assets/slider16.jpg"),
        URL =  require("./../../assets/slider13.jpg"),
        URL =  require("./../../assets/slider12.jpg"),
        URL =  require("./../../assets/slider11.jpg"),
        URL =  require("./../../assets/slider154.jpg"),
        URL =  require("./../../assets/slider14.jpg")
    ]

    useEffect(() => {
        if (fetchAp !== 'abc') {
            // console.log(fetchAp)
            setProData(fetchAp.newArrival)
            setProData1(fetchAp.promotional)
        }
    }, [fetchAp])

    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 100,
    //     slidesToShow: 3,
    //     slidesToScroll: 1
    // };


    const scrollleft1 = () => {
        var left = document.querySelector('#pro1');
        left.scrollBy(-300, 0)
    }
    const scrollright1 = () => {
        var right = document.querySelector('#pro1');
        right.scrollBy(300, 0)
    }

    const scrollleft2 = () => {
        var left = document.querySelector('#pro2');
        left.scrollBy(-300, 0)
    }
    const scrollright2 = () => {
        var right = document.querySelector('#pro2');
        right.scrollBy(300, 0)
    }
    const scrollleft3 = () => {
        var left = document.querySelector('#pro3');
        left.scrollBy(-300, 0)
    }
    const scrollright3 = () => {
        var right = document.querySelector('#pro3');
        right.scrollBy(300, 0)
    }

    const scrollleft4 = () => {
        var left = document.querySelector('#pro4');
        left.scrollBy(-300, 0)
    }
    const scrollright4 = () => {
        var right = document.querySelector('#pro4');
        right.scrollBy(300, 0)
    }



    return (
        <>

            <div className='all-body-items'>
                <div className='display-image'>
                    <div className='slider-image'>


                        <div className='banner-image'>
                            <SimpleImageSlider
                                width={'100%'}
                                height={'27vw'}
                                images={sliderImages}
                                showBullets={false}
                                showNavs={false}
                                autoPlay={true}
                                autoPlayDelay={2}
                                style={{ position: 'relative' }}
                            />
                        </div>

                    </div>

                    <div className='mobile-image-slider'>
                        <SimpleImageSlider
                            width={'100%'}
                            height={'40vw'}
                            images={sliderImages}
                            showBullets={false}
                            showNavs={false}
                            autoPlay={true}
                            autoPlayDelay={2}
                            style={{ position: 'relative' }}
                        />
                    </div>
                </div>


                <div className='items-sec'>
                    <h2> PROMOTIONAL ITEMS </h2>

                </div>
                <div style={{ position: "relative" }}>
                    <span className='view-more'>
                        <Link className='viewmore-text' to={'/productspage'} onClick={(e) => {
                            var data = "Grocery"
                            dispatch(ChangeName(data))
                            var dep = "0010";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            View More <BsChevronDoubleRight style={{ textAlign: "center" }} />
                        </Link>
                    </span>
                    <Apiproduct proData={proData1} setProData={setProData1} />

                </div>

                {/* <div className='items-sec'>
                    <h2> Products Category </h2>
                </div>
                <div className='category-container'>
                   
                   
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0009";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388948/category4_bs7apa.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0002";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388949/category5_dvwo7f.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0005";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388950/category6_a5qkal.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0008";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388955/category7_dwvrbd.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0014";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388952/category8_eqrovt.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0006";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388953/category9_rlyzor.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0007";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388953/category10_wm4nh5.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0013";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388954/category11_co5mdx.jpg'} alt='Products Category' /></Link>
                    </div>
                    <div className='category-products'>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0013";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653388955/category12_nvcbhw.jpg'} alt='Products Category' /></Link>
                    </div>

                </div> */}

                <div className='items-sec'>
                    <h2> SD Store Products </h2>

                </div>
                <div style={{ position: "relative" }}>
                    <span className='view-more'>
                        <Link className='viewmore-text' to={'/productspage'} onClick={(e) => {
                            var data = "Grocery"
                            dispatch(ChangeName(data))
                            var dep = "0004";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}>
                            View More <BsChevronDoubleRight style={{ textAlign: "center" }} />
                        </Link>
                    </span>
                    {/* <Apiproduct proData={proData} setProData={setProData} /> */}
                </div>

                <div className='items-sec'>
                    <div>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0010";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img className='webeximage' src={require("./../../assets/banners/B-01.png")}
                            alt=' Web Exclusive Offers' /></Link>
                    </div>

                    <div className='webexcontainer'>
                        {/* <div className='webexcontainer-img'>
                            <Link to={'/productspage'} onClick={(e) => {
                                var dep = "0010";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}><img src={require("./../../assets/banners/B-01.png")}
                                alt=' Web Exclusive Offers' /></Link>
                        </div> */}
                        <div className='webexcontainer-pro'>

                            <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft1} > <BsChevronLeft /> </button>
                            <div className='webexcontainer-pro2' id='pro1' >
                            <Apiproduct proData={proData1} setProData={setProData1} />
                            </div>
                            <button className='rightbutton' style={{ right: "0" }} onClick={scrollright1} > <BsChevronRight /> </button>
                        </div>
                        <span className='haddingtag3'>
                            <Link className='linkfount' to={'/productspage'} onClick={(e) => {
                                var data = "Grocery"
                                dispatch(ChangeName(data))
                                var dep = "0010";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}> View More <BsChevronDoubleRight /></Link>
                        </span>
                    </div>
                </div>
                <div className='items-sec'>


                    <div>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0004";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img className='webeximage' src={require("./../../assets/banners/B-02.png")}
                            alt='Fruits & Vegetables' /></Link>
                    </div>

                    <div className='webexcontainer'>
                        {/* <div className='webexcontainer-img'>
                            <Link to={'/productspage'} onClick={(e) => {
                                var dep = "0004";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner4_u415zu.webp`} alt='Fruits & Vegetables' /></Link>
                        </div> */}
                        <div className='webexcontainer-pro'>
                            <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft2} > <BsChevronLeft /> </button>
                            <div className='webexcontainer-pro2' id='pro2'>
                               
                                <FrozenApiproduct D={"0012"} b={""} />
                            </div>
                            <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright2}  > <BsChevronRight /> </button>
                        </div>
                        <span className='haddingtag3'>
                            <Link className='linkfount' to={'/productspage'} onClick={(e) => {
                                var data = "Fruits & Vegitables"
                                dispatch(ChangeName(data))
                                var dep = "0012";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}> View More <BsChevronDoubleRight /></Link>
                        </span>

                    </div>


                </div>
                <div className='items-sec'>


                    <div>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0012";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img className='webeximage' src={require("./../../assets/banners/B-03.png")} 
                        alt='Drinks & Juices' /></Link>
                    </div>

                    <div className='webexcontainer'>
                        {/* <div className='webexcontainer-img'>
                            <Link to={'/productspage'} onClick={(e) => {
                                var dep = "0008";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner6_rmweef.webp`} 
                            alt='Drinks & Juices' /></Link>
                        </div> */}
                        <div className='webexcontainer-pro'>
                            <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft3} > <BsChevronLeft /> </button>
                            <div className='webexcontainer-pro2' id='pro3'>
                          
                                <FrozenApiproduct D={"0013"} B={"0001"} />
                            </div>
                            <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright3}  > <BsChevronRight /> </button>
                        </div>
                        <span className='haddingtag3'>
                            <Link className='linkfount' to={'/productspage'} onClick={(e) => {
                                var data = "Drinks"
                                dispatch(ChangeName(data))
                                var dep = "0008";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}> View More <BsChevronDoubleRight /></Link>
                        </span>

                    </div>


                </div>
                <div className='items-sec'>


                    <div>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = "0009";
                            dispatch(PoductsApi(dep, ''))
                            e.stopPropagation();
                        }}><img className='webeximage' src={require("./../../assets/banner2.jpg")} 
                        alt='Frozen Foods' /></Link>
                    </div>

                    <div className='webexcontainer'>
                        {/* <div className='webexcontainer-img'>
                            <Link to={'/productspage'} onClick={(e) => {
                                var dep = "0009";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
                             alt='Frozen Foods' /></Link>
                        </div> */}
                        <div className='webexcontainer-pro'>
                            <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
                            <div className='webexcontainer-pro2' id='pro4'>
                             
                            <Apiproduct proData={proData} setProData={setProData} />
                            </div>
                            <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
                        </div>
                        <span className='haddingtag3'>
                            <Link className='linkfount' to={'/productspage'} onClick={(e) => {
                                var data = "Frozen Foods"
                                dispatch(ChangeName(data))
                                var dep = "0009";
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}> View More <BsChevronDoubleRight /></Link>
                        </span>
                    </div>
                </div>




                <div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-04.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct D={"0001"} b={"0001"}/>
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>






<div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-05.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct D={"0005"} b={""}/>
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>



<div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-06.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct  D={"0001"} b={"0004"}/>
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>




<div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-07.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct D={"0001"} b={"0006"}/>
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>



<div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-08.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct D={"0003"} b={"0003"}/>
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>



<div className='items-sec'>


<div>
    <Link to={'/productspage'} onClick={(e) => {
        var dep = "0009";
        dispatch(PoductsApi(dep, ''))
        e.stopPropagation();
    }}><img className='webeximage' src={require("./../../assets/banners/B-09.png")} 
    alt='Frozen Foods' /></Link>
</div>

<div className='webexcontainer'>
    {/* <div className='webexcontainer-img'>
        <Link to={'/productspage'} onClick={(e) => {
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}><img src={`https://res.cloudinary.com/sbstore/image/upload/v1657959998/sb%20banner/banner8_tv9ttm.webp`}
         alt='Frozen Foods' /></Link>
    </div> */}
    <div className='webexcontainer-pro'>
        <button className='leftbutton' style={{ left: "0" }} onClick={scrollleft4} > <BsChevronLeft /> </button>
        <div className='webexcontainer-pro2' id='pro4'>
            <FrozenApiproduct D={"0006"} b={""} />
        </div>
        <button className='rightbutton' id='001' style={{ right: "0" }} onClick={scrollright4}  > <BsChevronRight /> </button>
    </div>
    <span className='haddingtag3'>
        <Link className='linkfount' to={'/productspage'} onClick={(e) => {
            var data = "Frozen Foods"
            dispatch(ChangeName(data))
            var dep = "0009";
            dispatch(PoductsApi(dep, ''))
            e.stopPropagation();
        }}> View More <BsChevronDoubleRight /></Link>
    </span>
</div>
</div>



            </div>
        </>
    )
}

export default Body;